<template>
	<main class="page-container" id="sme-signup">

	</main>
</template>

<script>

export default {
	name: 'Signup',
	components: {

	},
	data: () => ({

	}),
	created() {
	},
	methods: {

	},
};
</script>
